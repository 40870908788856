import React from 'react';
import Box from '@mui/material/Box';

export default function VideoBox({ videoId }) {
  const videoSrc = `https://www.youtube.com/embed/${videoId}`;

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '640px', // Set your desired max width
        maxHeight: '360px', // Set your desired max height, adjust according to aspect ratio
        margin: 'auto', // This centers the video box
        width: '100%', // It takes up to 100% of the container width
        paddingTop: '56.25%', // Aspect ratio for 16:9 video, calculated as (9 / 16) * 100%
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        src={videoSrc}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </Box>
  );
};