import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const SlideWithReadMore = ({ content, limit = 250, isActive }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  
  // Use MediaQuery to detect if the screen is mobile
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // e.g., below 600px (mobile screens)

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Truncate the content if it's mobile and not expanded
  const truncatedContent = `${content.substring(0, limit)}`;

  // Display the full content if expanded or if on a larger screen
  const displayedContent = isMobile && !isExpanded ? truncatedContent : content;

  // Reset isExpanded when the slide becomes inactive
  useEffect(() => {
    if (!isActive) {
      setIsExpanded(false);
    }
  }, [isActive]);

  return (
    <Box sx={{ width: '100%', maxWidth: '1000px', marginTop: '20px' }}>
      <Typography style={{ fontSize: '0.7rem', textAlign: 'justify' }}>
        <span dangerouslySetInnerHTML={{ __html: displayedContent }} />
        {isMobile && (
          <span 
            onClick={handleReadMore} 
            style={{ color: 'grey', cursor: 'pointer' }}>
            {isExpanded ? ' See less' : '... See more'}
          </span>
        )}
      </Typography>
    </Box>
  );
};

export default SlideWithReadMore;
