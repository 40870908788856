import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';
import { Email, Phone, LocationOn, LinkedIn, YouTube  } from '@mui/icons-material';
import { useTypographyStyles, useLayoutStyles } from '../styles';


const useStyles = makeStyles(theme => ({
  mainHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 5,
    fontSize: '2.85rem',
    fontWeight: 'normal',
    color: '#53595D',
    flexDirection: "column",
  },

  headerParagraph: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down(600)]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    backgroundColor: '#fff',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  mousepointer: {
    cursor: 'pointer',
  },
  icon: {
    marginRight: '12px',
    fontSize: '32px !important',
    backgroundColor: 'transparent',
    color: 'black'
  },

  iconButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '10px 10px 0px 0px',
    backgroundColor: 'transparent',
    color: 'black'
  },

  submitButton: {
    mt: 3, 
    mb: 2, 
    px: 3, 
    width: { xs: '100%', sm: '120px' },
    backgroundColor: '#ffc103', 
    '&:hover': {
      backgroundColor: 'goldenrod', 
      boxShadow: 'none'
    }, 
    boxShadow: 'none',
  },

  addressSpan: {
    whiteSpace: 'nowrap', // Prevents the text from wrapping
  },
}));


const defaultTheme = createTheme();

export default function Contact() {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <div className={typogClasses.pageTitle}>
          <p>Contact <b>Us</b></p>
        </div>
        <Box
          sx={{
            marginTop: {xs: 4, md: 6},
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row'},
            justifyContent: 'center',
          }}
        >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mx:2,
            alignItems: {xs: 'center', md: 'flex-start'}
          }}
        >
          <Typography component="h2" variant="h5" sx={{ml: {xs:0, md: 2}}}>
            Contact details
          </Typography>
          <div className={classes.contactDetails}>
            <div className={`${classes.item} ${classes.mousepointer}`} onClick={() => {window.location.href = 'mailto:info@3dmorphic.com';}}>
              <Email className={classes.icon} />
              <span>info@3dmorphic.com</span>
            </div>
            <div className={`${classes.item} ${classes.mousepointer}`} onClick={() => {window.location.href = 'tel:+61 424 651 478';}}>
              <Phone className={classes.icon} />
              <span>+61 424 651 478</span>
            </div>
            <div className={classes.item}>
              <LocationOn className={classes.icon} />
              <span className={classes.addressSpan}>1/38 Raymond Ave Matraville <br/> NSW 2036 Australia</span>
            </div>
            <div className={classes.item}>
              <button className={classes.iconButton} onClick={() => openInNewTab('https://www.linkedin.com/company/3dmorphic/')}>
                <LinkedIn className={classes.icon} />
              </button>
              <button className={classes.iconButton} onClick={() => openInNewTab('https://www.youtube.com/channel/UCYOWHwMAdTs_g2Uuq0Hbyrw')}>
                <YouTube className={classes.icon} />
              </button>
              <button className={classes.iconButton} onClick={() => openInNewTab('https://twitter.com/3dmorphic')}>
                <svg xmlns="http://www.w3.org/2000/svg" height="23" width="23" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
              </button>
            </div>
          </div>
        </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

