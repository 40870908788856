import { makeStyles } from '@material-ui/core';
import { useTypographyStyles, useLayoutStyles } from '../styles';


const useStyles = makeStyles(theme => ({
  imageTextSectionRow: {
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    flexDirection: 'row',
    '@media (max-width: 920px)': {
      flexDirection: 'column-reverse',
    },
  },
  imageTextSectionRowReverse: {
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 10,
    flexDirection: 'row-reverse',
    '@media (max-width: 920px)': {
      flexDirection: 'column-reverse',
    },
  },
  imageSection: {
    marginLeft: -20,
    marginRight: -20,
    width: '50%',
    '@media (max-width: 920px)': {
      width: '85%',
    },
    height: 'auto',
    display: 'block',
  },
  textSectionTitle: {
    marginLeft: 80,
    marginRight: 80,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'left',
    color: '#53595D',
    fontSize: '2.25rem',
    fontWeight: 'normal',
    lineHeight: 1.5,
    [theme.breakpoints.down(600)]: {
      marginLeft: 20,
      marginRight: 20,
      fontSize: '2rem',
      textAlign: 'center',
    },
  },
  textSectionContent: {
    marginLeft: 80,
    marginRight: 80,
    marginBottom: 10,
    color: '#53595D',
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: 1.5,
    [theme.breakpoints.down(600)]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  imageSectionDepthEffect: {
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.4)', // Example shadow effect

    // Add more styling as needed to create the desired depth effect
  },

  
}));


const textWithLineBreaks = (text) => {
  return text.split('\\n').map((line, index) => (
    <span key={index}>
      {line}
      {index !== text.split('\\n').length - 1 && <br />}
    </span>
  ));
}

const ImageTextSection = ({ title, text, imageUrl, imageLeft, refr, hasDepthEffect }) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const sectionClass = imageLeft ? classes.imageTextSectionRow : classes.imageTextSectionRowReverse;
  const imageClass = hasDepthEffect ? `${classes.imageSection} ${classes.imageSectionDepthEffect}` : classes.imageSection;

  return (
    <div className={sectionClass}  ref={refr}>
        <img src={imageUrl} alt={title} className={imageClass} />
      <div>
        <div className={classes.textSectionTitle}>
          {title}
        </div>
        <div className={`${classes.textSectionContent} ${typogClasses.textJustifyCentre}`}>
          {textWithLineBreaks(text)}
        </div>
      </div>
    </div>
  );
};

export default ImageTextSection;