import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
  
// importing material UI components
import { makeStyles, AppBar, Toolbar, Drawer } from "@material-ui/core";
import { Stack, Box, useMediaQuery } from "@mui/material";

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';

import NavBtn from "./NavBtn"; 



const useStyles = makeStyles((theme) => ({
  appBar: {
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: 'none',
    position: 'sticky',
    top: 0,
    transition: 'transform 0.5s ease',
    backgroundColor: 'rgba(246, 246, 246, 0.95)',
    [theme.breakpoints.down(1400)]: {
      paddingTop: 3,
      paddingBottom: 3,
    },
    [theme.breakpoints.down(920)]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  toolbar: {
    maxWidth: '1300px',
    margin: 'auto',
    padding: '0px 30px',
    [theme.breakpoints.down(600)]: {
      padding: '0px 19px',
    },
  },

  globalContainer: {
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 20, // Adjust this for padding on the left
    paddingRight: 20, // Adjust this for padding on the right
  },
  logo: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    height: 45,
    marginRight: theme.spacing(2),
    cursor:"pointer",
    [theme.breakpoints.up(920)]: {
      height: 52,
    },
    [theme.breakpoints.up(1400)]: {
      height: 60,
    },
  },
  appBarHidden: {
    transform: 'translateY(-100%)',
  },
  button: {
    fontSize: 12,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const activeRoute = location.pathname;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastUpScrollPos, setLastUpScrollPos] = useState(0);
  const [headerVisibility, setHeaderVisibility] = useState(true);

  const mediumViewport = useMediaQuery("(min-width:920px)");

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.innerWidth > 920) {
  //       setHeaderVisibility(true); // Keep the header always visible
  //     } else {
  //       const currentScrollPos = window.scrollY;
  //       const headerVisibility = 
  //         currentScrollPos < scrollPosition ||              // scrolling up
  //         (currentScrollPos - lastUpScrollPos) <= 100 ||    // scrolled less than 50px from turn around
  //         currentScrollPos <= 150;                          // top of the page

  //       if (currentScrollPos < scrollPosition) {
  //         setLastUpScrollPos(currentScrollPos);
  //       }

  //       setHeaderVisibility(headerVisibility);
  //       setScrollPosition(currentScrollPos);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   window.addEventListener('resize', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     window.removeEventListener('resize', handleScroll);
  //   }
  // }, [scrollPosition]);

  

  const handleMenuClicked = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const navDrawerClick = (route) => {
    setSideBarOpen(false);
    navigate(route)
  };

  return (
    <AppBar className={`${classes.appBar} ${!headerVisibility && classes.appBarHidden}`}>
      <div>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logo} onClick={() => navDrawerClick("/")}>
          {/* <img src="https://d3fcjysp85tqc8.cloudfront.net/3dmorphic_logo.webp" alt="Logo" className={classes.logoImage} /> */}
          {/* <img src="https://d3fcjysp85tqc8.cloudfront.net/3DM_Logo_with_R_words_below-removebg.webp" alt="Logo" className={classes.logoImage} /> */}
          {/* <img src="https://d3fcjysp85tqc8.cloudfront.net/3DM_logo_R_symbol.webp" alt="Logo" className={classes.logoImage} /> */}
          <img src="https://d3fcjysp85tqc8.cloudfront.net/Logo.svg" alt="Logo" className={classes.logoImage} />
        </div>
        { mediumViewport ? 
          <Stack spacing={2} direction="row" sx={{mt:1}}>
            <NavBtn onClick={() => navDrawerClick("/"             )} isActive={activeRoute === "/"      } >Home </NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Difference"   )} isActive={activeRoute === "/Difference"} >Difference </NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Products"     )} isActive={activeRoute === "/Products"  } >Products</NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Services"     )} isActive={activeRoute === "/Services"  } >Services</NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Media"        )} isActive={activeRoute === "/Media"     } >Media</NavBtn>
            <NavBtn onClick={() => navDrawerClick("/Contact-us"   )} isActive={activeRoute === "/Contact-us"} >Contact Us</NavBtn>
          </Stack>
          :
          // <MenuRoundedIcon onClick={handleMenuClicked} style={{ cursor:"pointer", color: '#464946', fontSize: 30, marginTop: 5, marginRight: 5 }}/>
          <IconButton
            onClick={handleMenuClicked}
            disableRipple
            sx={{
              color: '#464946',
              '&:focus': {
                outline: 'none',
              },
              '&:active': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <MenuRoundedIcon fontSize="large" />
          </IconButton>
        }
        <Drawer open={sideBarOpen} anchor="right" onClose={handleMenuClicked}>
          <Box sx={{ width: {xs: "85vw", sm:"70vw"} }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={handleMenuClicked} sx={{ cursor:"pointer", m: 2, fontSize: 35 }}>
                <CloseRoundedIcon/>
              </IconButton>

            </Box>
            <Stack sx={{ width: 270, ml:"12%", mt: 2 }} spacing={3.5} direction="column">
              <NavBtn alignLeft={true} onClick={() => navDrawerClick("/"           )} isActive={activeRoute === "/"      } >Home </NavBtn>
              <NavBtn alignLeft={true} onClick={() => navDrawerClick("/Difference" )} isActive={activeRoute === "/Difference"}>Difference</NavBtn>
              <NavBtn alignLeft={true} onClick={() => navDrawerClick("/Products"   )} isActive={activeRoute === "/Products"  }>Products</NavBtn>
              <NavBtn alignLeft={true} onClick={() => navDrawerClick("/Services"   )} isActive={activeRoute === "/Services"  }>Services</NavBtn>
              <NavBtn alignLeft={true} onClick={() => navDrawerClick("/Media"      )} isActive={activeRoute === "/Media"     }>Media</NavBtn>
              <NavBtn alignLeft={true} onClick={() => navDrawerClick("/Contact-us" )} isActive={activeRoute === "/Contact-us"}>Contact Us</NavBtn>
            </Stack>
          </Box>
        </Drawer>
      </Toolbar>
      </div>
    </AppBar>
  );
}
