import React from 'react';
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useTypographyStyles, useLayoutStyles } from '../styles';
import VideoBox from "../components/VideoBox"; 
import CustomKeenSlider from '../components/RotatingKeenSlider';
import SliderWithArrows from '../components/SimpleSlider';
import SlideWithReadMore from '../components/SlideWithReadMore';
import BlogSlide from '../components/blogSlide';
import PublishedArticles from '../components/PublishedArticles';

const useStyles = makeStyles(theme => ({
  header: {
    height: '100px',
  },
  Title: {
    maxHeight: '130px',
    margin: theme.spacing(1), 
    textAlign: 'center',
  },
  stackContainer: {
    maxWidth: 1300,
    margin: theme.spacing(2), 
    marginTop: theme.spacing(1), 
    [theme.breakpoints.up(800)]: {
      margin: theme.spacing(3), 
      marginTop: theme.spacing(1),
    },
  },
  videoBox: {
    backgroundColor: '#f6f6f6',
    padding: theme.spacing(3),
    [theme.breakpoints.up(900)]: {
      width: "100%",
    },
  },
  spacing_bottom: {
    height: '60px',
  },
}));

export default function Media() {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));

  const case_study_slides = [
    (isActive) => (
      <div style={{padding: '2rem'}}>
        <div style={{ backgroundColor: '#f6f6f6', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Slider with Arrows - Centered and controlled */}
          <Box sx={{width: { xs: '100%', sm: '70%' }, maxWidth: '1000px', marginTop: '20px' }}>
            <SliderWithArrows 
              images={['https://d3fcjysp85tqc8.cloudfront.net/media_page/turnAround1.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/turnAround2.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/turnAround3.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/turnAround4.webp']}
              arrowColor="#FFFFFF"
            />
          </Box>

          {/* Text Container with full width control */}
          <SlideWithReadMore
            content={`A big misconception is that patient specific implants need a lot of time to produce. But maybe this example could challenge this preconception.
              <br></br>
              ​During a football game, a 31-year-old male was tackled, landed on the top of his head and suffered a burst fracture to his C7 vertebral body. This resulted 
              in spinal cord compression and potential life-long paralysis. 
              <br></br>
              ​3DMorphic's just-in-time processes enabled a custom-made implant to be manufactured fast enough to treat this emergency trauma case. This debunks the myth 
              that such implants are exclusively for scheduled procedures with ample lead time. Having the possibility of devices custom-made according to the patient's 
              anatomy and specific pathology is a paradigm shift in the treatment of trauma cases like this. It allows for tailored solutions to complex injuries, improving
              patient outcomes and reducing recovery time.`}
              limit={300} // Character limit for truncated text
              isActive={isActive}
          />
        </div>
      </div>
    ),

    (isActive) => (
      <div style={{padding: '2rem'}}>
        <div style={{ backgroundColor: '#f6f6f6', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{width: { xs: '100%', sm: '70%' }, maxWidth: '1000px', marginTop: '20px' }}>
            <SliderWithArrows 
              images={['https://d3fcjysp85tqc8.cloudfront.net/media_page/young1.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/young2.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/young3.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/young4.webp']} 
            />
          </Box>
          {/* Text Container with full width control */}
          <SlideWithReadMore
              content={`A 22-year-old man's quality of life was significantly impacted by ongoing challenges and new pain following an initial surgery. 
                He had chiari malformation and hypermobility that led to him needing an occipito-cervical fusion C0 to C3 using conventional posterior fixation 
                (rods and screws) and abundant bone graft. The initial surgery relieved his hypermobility symptoms but did not achieve the desired permanent bony 
                union because there was too much movement in the joint for a successful fusion. The bone graft used in the first surgery astonishingly reabsorbed 
                within six months because the biomechanical conditions were not right for fusion. The pain returned after one of the screws loosened because fusion 
                had not taken place and as A/Prof Seex noted, '...clearly conventional techniques have not worked.'.
                <br></br>
                In response, the surgeon proposed a revision surgery using custom-made fusion devices for the C1/C2 facet joints and sought out 3DMorphic's technology 
                as the solution to meet to the patient's requirements.​​ Immediately after surgery, the patient felt better. Five months post his revision surgery, the patient 
                improved considerably both in terms of alignment and fusion, which has enabled him to start getting his life back on track.​`}
                limit={300}
                isActive={isActive}
          />
        </div>  
      </div>
    ),

    (isActive) => (
      <div style={{padding: '2rem'}}>
        <div style={{ backgroundColor: '#f6f6f6', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{width: { xs: '100%', sm: '70%' }, maxWidth: '1000px', marginTop: '20px' }}>
            <SliderWithArrows 
              images={['https://d3fcjysp85tqc8.cloudfront.net/media_page/SmallBig1.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/SmallBig2.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/SmallBig3.webp']} 
            />
          </Box>
          {/* Text Container with full width control */}
          <SlideWithReadMore
            content={`In spinal fusion, small improvements make a big difference.</br> For 25 years, a woman lived in constant pain from an initial whiplash injury, 
              "My brain learned new pathways knowing I was always in pain”. She suffered from neck pain due to loss of disc height, lordotic angle, and subchondral 
              bone remodelling at C4-5, C5-6, and C6-7. A triple-level fusion is a major operation with many risks; however, 3DMorphic designed the realignment into 
              patient-specific implants, which the surgeon chose to use.​<br></br>Post-operative scans showed:</br>- Restoration of normal neck curvature, 
              decompressing the spinal cord.</br>- Symmetrical device positioning.</br>- Uniform behaviour across the three operated levels.​​​<br></br> These improvements protect 
              adjacent discs from degeneration by reducing abnormal angulation. The surgeon noted, “The plain X-rays look good, showing a nice restoration of cervical lordosis". 
              The patient, ecstatic at her reduced pain seven months after surgery, remarked, "Even people around me have noticed a difference in my confidence.” She’s now more 
              active, social, and free from constant severe pain. Her journey demonstrates how small corrections in the implant can lead to a remarkable outcome.​`}
              limit={300}
              isActive={isActive}
          />
        </div>
      </div>
    ),

    (isActive) => (
      <div style={{padding: '2rem'}}>
        <div style={{ backgroundColor: '#f6f6f6', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{width: { xs: '100%', sm: '70%' }, maxWidth: '1000px', marginTop: '20px' }}>
            <SliderWithArrows 
              images={['https://d3fcjysp85tqc8.cloudfront.net/media_page/planning1.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/planning2.webp']} 
            />
          </Box>
          {/* Text Container with full width control */}
          <SlideWithReadMore
            content={`Pre-operative planning is an important aspect of the surgical process. It allows clinicians to develop a deeper insight the patient's unique 
              anatomy before surgery, minimising surprises and enabling confident and efficient procedures. This ensures that every aspect of the surgery is personalised, 
              and helps to reduce tissue trauma, surgical time and improve overall accuracy for optimal outcomes. ​
              <br></br>
              With this recent case, 3DMorphic showcases the power of pre-operative planning. Intra-operative imagery has confirmed the precision of our planning with the 
              snug fit of our patient-specific implants, by showing an intra-operative result which replicates the pre-operative plan. We think that a focus on meticulous 
              planning can provide surgeons with additional understanding of the interaction between the implant and patient anatomy to achieve great post-operative outcomes.​`}
              limit={300}
              isActive={isActive}
          />
        </div>
      </div>
    ),

    (isActive) => (
      <div style={{padding: '2rem'}}>
        <div style={{ backgroundColor: '#f6f6f6', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{width: { xs: '100%', sm: '70%' }, maxWidth: '1000px', marginTop: '20px' }}>
            <SliderWithArrows 
              images={['https://d3fcjysp85tqc8.cloudfront.net/media_page/RevisionCase1.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/RevisionCase2.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/RevisionCase3.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/RevisionCase4.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/RevisionCase5.webp']} 
            />
          </Box>
          {/* Text Container with full width control */}
          <SlideWithReadMore
            content={`Complicated revisions demand effective solutions.
              <br></br>
              Revision surgeries are a reality in spinal fusion, and the risk of failure increases with each attempt: 70% of 1st revisions fail, 85% of 2nd, and 95% of 3rd 
              (Daniell and Osti 2018). Most importantly, each failure worsens the patient’s quality of life. Therefore, the new implant must immediately stabilise the joint 
              and increase bone-to-device contact to promote faster fusion and reduce complications, especially when the anatomy has changed unpredictably.
              <br></br>
              A 39-year-old man faced this after his disc replacement failed, causing wear debris and bone breakdown. The surgeon opted for a patient-specific implant to fit 
              the expanded intervertebral space, which had numerous bone voids. Post-operative scans showed successful realignment and fusion.
              <br></br> 
              Patient specific implants address some of the unique challenges of revision surgeries, reducing the risk of recurrence and improving patient outcomes.​`}
              limit={300}
              isActive={isActive}
          />
        </div>
      </div>
    ),

    (isActive) => (
      <div style={{padding: '2rem'}}>
        <div style={{ backgroundColor: '#f6f6f6', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{width: { xs: '100%', sm: '70%' }, maxWidth: '1000px', marginTop: '20px' }}>
            <SliderWithArrows 
              images={['https://d3fcjysp85tqc8.cloudfront.net/media_page/Hemi1.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/Hemi2.webp']} 
            />
          </Box>
          {/* Text Container with full width control */}
          <SlideWithReadMore
            content={`At 3DMorphic we provide surgeons with PSI options to achieve the desired correction for the patient. ​
              <br></br>
              In this 3DMorphic case, the 53yo male patient was diagnosed with L4 lateral hemivertebrae and degenerative changes at the L4/L5 and L5/S1 levels, which caused him 
              escalating back and bilateral leg pain. This is explained by the loss of disc and neuroforaminal heights. The surgeon decided to implant patient specific fusion devices 
              at L4/L5 and L5/S1 levels. To accommodate intraoperative variations, 3DMorphic provided different device sizes for each level. All devices were designed to fit the endplate 
              anatomy, maintaining optimal contact footprint, but with different heights and lordotic angles.​ The surgeon made the intra-operative decision to implant the smallest option 
              at L4/L5 whilst using a larger option at L5/S1. Post-operative monitoring showed significant improvement in back and leg pain, returning to near-normal function.`}
              limit={300}
              isActive={isActive}
          />
        </div>
      </div>
    ),

    (isActive) => (
      <div style={{padding: '2rem'}}>
        <div style={{ backgroundColor: '#f6f6f6', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{width: { xs: '100%', sm: '70%' }, maxWidth: '1000px', marginTop: '20px' }}>
            <SliderWithArrows 
              images={['https://d3fcjysp85tqc8.cloudfront.net/media_page/Spondy1.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/Spondy2.webp', 'https://d3fcjysp85tqc8.cloudfront.net/media_page/Spondy3.webp']} 
            />
          </Box>
          {/* Text Container with full width control */}
          <SlideWithReadMore
            content={`At 3DMorphic, we understand that patient specific devices can play an important role in achieving suitable alignment.​ To achieve appropriate realignment correction, 
              the cage must fit the endplate anatomy. Equally, to achieve the best fit, the cage needs to be designed with the suitable alignment correction.
              <br></br>
              Achieving  realignment is not just a goal; it's a necessity for good patient outcomes.
              <br></br>
              In this 3DMorphic case, the patient had spondylolisthesis, with L4 translating anteriorly relative to the L5 vertebral body. They have an abnormal curvature of the 
              L4-L5 disc space that couldn't accommodate a standard flat-sided implant.​​ With our implants and the surgeons expertise, the patient's spine was realigned correcting the anterior slip of L4 whilst 
              restoring lumbar lordosis, resulting in a remarkable improvement in their quality of life.​​`}
              limit={300}
              isActive={isActive}
          />
        </div>
      </div>
    ),
  ];


  const blog_slides = [
    (isActive) => (
      <BlogSlide
        isActive={isActive}
        imageSrc="https://d3fcjysp85tqc8.cloudfront.net/media_page/article_EP.webp"
        linkHref="https://www.linkedin.com/pulse/endplate-remodelling-spinal-fusion-beneficial-detrimental-3dmorphic-kjwkf/"
        altText="Endplate Remodelling Article"
      />
    ),
    (isActive) => (
      <BlogSlide
        isActive={isActive}
        imageSrc="https://d3fcjysp85tqc8.cloudfront.net/media_page/article_tech.webp"
        linkHref="https://www.materialise.com/en/inspiration/articles/fusing-technology-custom-spine-implants"
        altText="Custom Implants Tech Article"
      />
    ),
    // Add more slides as needed
  ];

  return (
    <>
      <Box className={classes.header}>
        <div className={typogClasses.pageTitle}>
          <p>Insights</p>
        </div>
      </Box>
      <Box className={classes.Title}>
        <div className={typogClasses.pageInfo} style={{ fontSize: '2.5rem' }}>
          <p>News</p>
        </div>
      </Box>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 4 }} className={classes.stackContainer}>
          <Box className={classes.videoBox}>
            <VideoBox videoId="F6pfJONWK7k" />
            <Typography variant="h5" className={classes.textHeader} sx={{ my: 2, textAlign: 'left' }}>
              <b>3DMorphic Custom Spinal Cage for DDD Patient (2017)</b>
            </Typography>
            <Typography variant="body1" className={classes.paragraph} sx={{ mt: 2, textAlign: 'justify' }}>
              This 9News story features a 3DMorphic custom-made ALIF spinal implant, designed and manufactured for a patient with Degenerative Disc Disease (DDD). The operation was successful, providing long-term pain relief for the patient. 
            </Typography>
          </Box>
          <Box className={classes.videoBox}>
            <VideoBox videoId="T5bqcFTTtpk" />
            <Typography variant="h5" className={classes.textHeader} sx={{ my: 2, textAlign: 'left' }}>
              <b>3DMorphic Custom Cage for Cancer Patient (2018)</b>
            </Typography>
            <Typography variant="body1" className={classes.paragraph} sx={{ mt: 2, textAlign: 'justify' }}>
              This 7News story features a 3DMorphic custom-made cervical corpectomy spinal implant, designed and manufactured in 2018 for a patient with bone cancer. Three vertebral bodies were removed and replaced with a 3DMorphic cage. As seen in the video, the patient experienced a remarkable recovery.
            </Typography>
          </Box>
          <Box className={classes.videoBox}>
            <VideoBox videoId="HhMqIxU6aQg" />
            <Typography variant="h5" className={classes.textHeader} sx={{ my: 2, textAlign: 'left' }}>
              <b>3DMorphic Custom Cage for Trauma Patient (2021)</b>
            </Typography>
            <Typography variant="body1" className={classes.paragraph} sx={{ mt: 2, textAlign: 'justify' }}>
              This 7News story features a 3DMorphic custom-made cervical spinal implant, designed and manufactured in 2021 for an emergency trauma patient. This was a landmark case being the first patient-specific spinal trauma device. The case demonstrated that 3DMorphic’s process is fast enough to achieve the necessary turnaround, something thought impossible a few years ago. 
            </Typography>
          </Box>
        </Stack>
      </Box>

      {/* Case Studies Slider */}
      <Box className={classes.Title}>
        <div className={typogClasses.pageInfo} style={{ fontSize: '2.5rem', paddingTop: '10px' }}>
          <p>Case Studies</p>
        </div>
      </Box>
      <CustomKeenSlider slides={case_study_slides}/>

      {/* Blogs Slider */}
      <Box className={classes.Title}>
        <div className={typogClasses.pageInfo} style={{ fontSize: '2.5rem', paddingTop: '10px' }}>
          <p>Blogs</p>
        </div>
      </Box>
      <CustomKeenSlider slides={blog_slides}/>

      <Box className={classes.Title}>
        <div className={typogClasses.pageInfo} style={{ fontSize: '2.5rem', paddingTop: '10px' }}>
          <p>Published peer-reviewed Articles</p>
        </div>
      </Box>

      <PublishedArticles />
      <Box className={classes.spacing_bottom}>
      </Box>

    </>
  );
}
