import { useState } from 'react';
import Box from '@mui/material/Box';
import { YellowBtnContained } from "../components/YellowBtn";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '85%', sm: 400 },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  px: { xs: 2, sm: 4 },
  py: { xs: 3, sm: 4 },
};

export default function HealthCareProfPopup({ open, yesPressed, noPressed }) {
  return (
    <div>
      <Modal
        open={open}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant = "h6" sx={{ mt: 2, mb: 4 }}>
            These products are intended for healthcare professionals. By selecting YES you confirm
            you are a heathcare professional and consent to view the contents of this page.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '30px' }}>
            <YellowBtnContained style={{width: "110px"}} onClick={yesPressed}>Yes</YellowBtnContained>
            <YellowBtnContained style={{width: "110px"}} onClick={noPressed}>No</YellowBtnContained>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}