// BlogSlide.js

import React from 'react';
import { Box, Button } from '@mui/material'; // Adjust the import based on your setup
import AddCircleIcon from '@mui/icons-material/AddCircle';


const BlogSlide = ({ imageSrc, linkHref, altText = 'Slide Image', isActive }) => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      borderRadius: '20px',
      paddingTop: '70%', // Maintain aspect ratio
    }}
  >
    {/* Image Wrapper with Padding */}
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: '2rem', // Adjust padding as needed
        boxSizing: 'border-box',
        borderRadius: 'inherit',
        overflow: 'hidden',
      }}
    >
      {/* Background Image */}
      <Box
        component="img"
        src={imageSrc}
        alt={altText}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: 'inherit',
        }}
      />
    </Box>

    {/* Read More Button */}
    <Button
      variant="contained"
      style={{ backgroundColor: "rgba(83, 89, 93, 0.6)" }}
      href={linkHref}
      target="_blank"
      rel="noopener noreferrer"
      endIcon={<AddCircleIcon/>}
      sx={{
        position: 'absolute',
        top: '82%',
        left: '50%',
        transform: 'translate(-50%, -82%)',
        padding: '4px 30px',
        borderRadius: '20px',
        color: '#000000',
        fontSize: {
          xs: '0.55rem',
          sm: '0.6rem',
          md: '0.65rem',
          lg: '0.7rem',
          xl: '0.8rem',
        },

        textTransform: 'none', // Display text as typed
      }}
    >
      Read More
    </Button>
  </Box>
);

export default BlogSlide;
