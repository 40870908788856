import { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import ScrollTo from '../components/ScrollTo';
import HealthCareProfPopup from '../components/HealthCareProfPopup';
import { useAppState } from '../components/AppStateContext';
import { useTypographyStyles, useLayoutStyles } from '../styles';
import colours from '../styles/colours';
import { Link } from 'react-router-dom';

const DotPointText = ({ items }) => {
  const typogClasses = useTypographyStyles();
  return (
    <div className={typogClasses.DotPointInfo}>
      {items.map((item, index) => (
        <div key={index} className={typogClasses.pageInfo}>
          <span className={typogClasses.dotPoint}></span>
          {item}
        </div>
      ))}
    </div>
  );
};

const Frame = ({ title, imageUrl, onClick, leftElseRight }) => {
  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const imageSideClass = leftElseRight ? layoutClasses.contentsLeft : layoutClasses.contentsRight;
  return (
    <Grid item xs={12} sm={6} md={3} onClick={onClick}>
      <div className={layoutClasses.frameContainer}>
        <div className={layoutClasses.frameContent}>
          <div className={layoutClasses.frameTitleContainer}> {/* New Title Container */}
            <div className={typogClasses.productFrameTitle}>{title}</div>
          </div>
          <div className={`${layoutClasses.frameImageContainer} ${imageSideClass}`}>
            <img src={imageUrl} alt={title} className={layoutClasses.frameImage} />
          </div>
        </div>
      </div>
    </Grid>
  );
};

const ImageTextImageSection = ({ leftImageUrl, rightImageUrl, title, text, additionalText, rightImageWidth, refr }) => {
  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const titleParts = title.split(" ");
  const firstWord = titleParts.slice(0, -1).join(" ");
  const restOfTitle = titleParts[titleParts.length - 1];

  const rightImageBoxStyle = {
    width: rightImageWidth || '40%',
    margin: '0 auto',
    textAlign: 'center',
    height: '100%',
  };

  return (
    <div className={layoutClasses.imageTextImageSection} ref={refr}>
      <div className={layoutClasses.halfSection}>
        <img src={leftImageUrl} alt="Left" className={layoutClasses.leftImage} />
      </div>
      <div className={layoutClasses.halfSection}>
        <h2 className={typogClasses.ProductRichTextTitle}>
          <span style={{ color: colours.organgeYellow }}>{firstWord}</span>
          <span>{` ${restOfTitle}`}</span>
        </h2>
        <div className={`${typogClasses.pageInfo} ${typogClasses.greyText} ${typogClasses.textJustifyCentre}`}>
          <p>{text}</p>
        </div>
        <div className={layoutClasses.rightImageBox} style={rightImageBoxStyle}>
          <img src={rightImageUrl} alt="Right" className={layoutClasses.rightImage} />
        </div>
        <p className={typogClasses.additionalText}>{additionalText}</p>
      </div>
    </div>
  );
};

export default function Products() {
  const navigate = useNavigate();

  const { hasShownModal, setHasShownModal } = useAppState();
  const [open, setOpen] = useState(false);
  const yesPressed = () => {
    setOpen(false);
    setHasShownModal(true);
  }
  const noPressed = () => navigate("/");

  useEffect(() => {
    if (!hasShownModal) {
      setOpen(true);
    }
  }, [hasShownModal, setHasShownModal]);

  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const ref_ALIF = useRef(null);
  const ref_ACDF = useRef(null);
  const ref_VCC = useRef(null);
  const ref_LIF = useRef(null);

  return (
    <div className={layoutClasses.globalContainer}>
      <HealthCareProfPopup open={open} yesPressed={yesPressed} noPressed={noPressed}/>
      <div className={layoutClasses.sectionContainer}>
        <div className={layoutClasses.TitleInfoContainer}>
          <div className={typogClasses.pageTitle}>
            <p>Spinal <b>Products</b></p>
          </div>
          <div className={`${typogClasses.pageInfo} ${layoutClasses.leftRightMargin} ${typogClasses.textJustifyCentre}`}>
            Spinal fusion cages are implantable devices fixed between two vertebral 
            bodies of a patient's spine, fusing the bone together, providing stabilisation 
            and pain reduction. All implantable devices require surgeon consultation. 
            3DMorphic devices are indicated for patients with the following conditions:
          </div>
          <DotPointText
            items={['Degenerative Disc Disease (DDD)',
                    'Disc bulging or herniation',
                    'Spinal stenosis resulting in myelopathy',
                    'Foraminal stenosis resulting in radiculopathy',
                    'Pseudoarthrosis of two or more adjacent spinal interbody levels']}  />
        </div>
        <div className={`${layoutClasses.TitleInfoContainer} ${layoutClasses.topMargin}`}>
          <Grid container spacing={2}> {/* spacing={2} adds space between grid items */}
            <Frame title="ALIF DEVICES" imageUrl="https://d3fcjysp85tqc8.cloudfront.net/Products1.webp" onClick={() => ScrollTo(ref_ALIF)} leftElseRight={true}/>
            <Frame title="ACDF DEVICES" imageUrl="https://d3fcjysp85tqc8.cloudfront.net/Products2.webp" onClick={() => ScrollTo(ref_ACDF)} leftElseRight={true}/>
            <Frame title="CORPECTOMY DEVICES" imageUrl="https://d3fcjysp85tqc8.cloudfront.net/Products3.webp" onClick={() => ScrollTo(ref_VCC)} leftElseRight={false}/>
            <Frame title="LATERAL LIF DEVICES" imageUrl="https://d3fcjysp85tqc8.cloudfront.net/Products4.webp" onClick={() => ScrollTo(ref_LIF)} leftElseRight={false}/>
          </Grid>
        </div>
        <div className={layoutClasses.imageTextImageSectionContainer}>
          <ImageTextImageSection 
            leftImageUrl="https://d3fcjysp85tqc8.cloudfront.net/ALIF_products.webp" 
            rightImageUrl="https://d3fcjysp85tqc8.cloudfront.net/Device1.webp" 
            title="ALIF DEVICES" 
            text="Anterior Lumbar Interbody Fusion (ALIF) devices are cages implanted in the lower spine using an open anterior surgical approach." 
            additionalText="ALIF"
            refr={ref_ALIF}
          />
          <ImageTextImageSection 
            leftImageUrl="https://d3fcjysp85tqc8.cloudfront.net/ACDF_products.webp" 
            rightImageUrl="https://d3fcjysp85tqc8.cloudfront.net/Device2.webp" 
            title="ACDF DEVICES" 
            text="Anterior Cervical Discectomy and Fusion (ACDF) devices are cages implanted in the neck using an open anterior surgical approach." 
            additionalText="ACDF"
            rightImageWidth={'30%'}
            refr= {ref_ACDF}
          />
          <div className={typogClasses.productCustomTitle}>
            CUSTOM DESIGNS
          </div>
          <ImageTextImageSection 
            leftImageUrl="https://d3fcjysp85tqc8.cloudfront.net/VCC_products.webp" 
            rightImageUrl="https://d3fcjysp85tqc8.cloudfront.net/Device3.webp" 
            title="CORPECTOMY DEVICES" 
            text="A corpectomy device is a cage designed to replace the entire vertebral body and the adjoining discs. 3DMorphic produces both
            lumbar and cervical corpectomy cages." 
            additionalText="CERVICAL CORPECTOMY"
            refr= {ref_VCC}
          />
          <ImageTextImageSection 
            leftImageUrl="https://d3fcjysp85tqc8.cloudfront.net/LLIF_products.webp" 
            rightImageUrl="https://d3fcjysp85tqc8.cloudfront.net/Device4.webp" 
            title="LATERAL LIF DEVICES" 
            text="Lateral Lumbar Interbody Fusion devices are cages implanted in the lower anterior column using an open lateral surgical approach." 
            additionalText="LATERAL LIF"
            refr= {ref_LIF}
          />
        </div>
        <div className={`${typogClasses.pageInfo} ${layoutClasses.leftRightMargin} ${typogClasses.textJustifyCentre}`}>
            <Link to="/Contact-us" className={typogClasses.boldLink}>
              Contact us
            </Link> for more custom solutions.
        </div>
      </div>
    </div>
  );
}



