import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(4),
  },
  articleList: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    position: 'relative',
  },
  articleItem: {
    position: 'relative',
    padding: theme.spacing(0.5),
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  articleTextContainer: {
    display: 'inline-block',
    textAlign: 'justify',
    position: 'relative',
    maxWidth: '85%',
    zIndex: 1,
    [theme.breakpoints.down('1020')]: {
      width: '100%', // Full width on smaller screens
      maxWidth: '100%',
      textAlign: 'left',
    },
  },
  articleLink: {
    color: '#747474',
    textDecoration: 'none',
    fontSize: '0.85rem',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('1020')]: {
      textDecoration: 'underline', // Permanently underlined below 1020px
    },
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    height: '2px',
    backgroundColor: '#f5ce24',
    width: '0', // Initially hidden
    transition: 'width 0.5s ease',
    zIndex: 0,
    [theme.breakpoints.down('1020')]: {
      display: 'none', // Hide arrow below 1020px
    },
  },
  abstractSection: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.2)',
    visibility: 'hidden',
    opacity: 0,
    transform: 'translateY(20px)',
    transition: 'opacity 0.8s ease, transform 0.8s ease',
    [theme.breakpoints.down('1020')]: {
      display: 'none', // Hide abstract below 1020px
    },
  },
  abstractSectionVisible: {
    visibility: 'visible',
    opacity: 1,
    transform: 'translateY(0)', // Slide up effect
  },
  abstractTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
  },
  abstractText: {
    fontSize: '0.95rem',
    color: '#4a4a4a',
  },
}));

const PublishedArticles = () => {
  const classes = useStyles();
  const [abstract, setAbstract] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [arrowWidths, setArrowWidths] = useState({});
  const containerRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1020);

  const articles = [
    { 
        title: "Opinion Piece: Patient-Specific Implants May Be the Next Big Thing in Spinal Surgery", 
        abstract: "Patient-specific implants (PSIs) developed through three-dimensional printing have increasingly been applied to spinal surgery over the past five years, mainly in complex cases. PSIs aim to improve surgical outcomes by enhancing the implant-bone interface and optimizing surgical workflow. Despite their potential benefits, widespread adoption faces obstacles such as a lack of quality long-term data, research challenges, production practicalities, and regulatory hurdles. The literature recognizes the significant potential of spinal PSIs but emphasizes the need to address key questions to guide future clinical and research practices. The spinal surgical community is encouraged to ethically offer PSIs to facilitate larger comparative studies while ensuring optimal patient care." ,
        url: "https://pmc.ncbi.nlm.nih.gov/articles/PMC8228233/",
    },
    { 
        title: "Anterior lumbar interbody fusion using a personalized approach: is custom the future of implants for anterior lumbar interbody fusion surgery?", 
        abstract: "Spine surgery benefits from three-dimensional (3D) printing technology, especially in complex cases. A 34-year-old man with bilateral L5 radiculopathy underwent anterior lumbar interbody fusion using a custom 3D-printed interbody implant. The implant featured corrective angulation to restore lumbar lordosis, preplanned screw holes, and an end plate interface matching the patient's anatomy. The use of this patient-specific implant reduced operative time and provided immediate stability. The study concludes that custom implants enhance surgical outcomes by offering a superior anatomical fit compared to generic devices, potentially improving the restoration of normal anatomy. Preoperative planning with 3D models aids in surgical preparation and may offset the costs associated with increased planning time." ,
        url: "https://www.sciencedirect.com/science/article/abs/pii/S1878875019300038",
    },
    { 
        title: "L5 en-bloc vertebrectomy with customized reconstructive implant: comparison of patient-specific versus off-the-shelf implant", 
        abstract: "A 64-year-old man with a solitary lesion in the L5 vertebral body underwent surgery where a custom patient-specific implant (PSI) and a customizable off-the-shelf (OTS) implant were compared intraoperatively. The PSI was implanted in 90 seconds, while the OTS implant took over 40 minutes. The PSI provided superior immediate press-fit, firmness, and ease of bone grafting, along with reduced operative time and blood loss. This case demonstrates that three-dimensional printed PSIs can significantly enhance surgical efficiency and outcomes in spine surgery. Preoperative planning and surgeon training with 3D models further maximize patient outcomes by allowing for a superior anatomical fit and restoration." ,
        url: "https://www.sciencedirect.com/science/article/abs/pii/S1878875018301219",
    },
    { 
        title: "3D-printed spine surgery implants: a systematic review of the efficacy and clinical safety profile of patient-specific and off-the-shelf devices", 
        abstract: "This systematic review assessed the clinical efficacy and safety of three-dimensional printed (3DP) spinal implants, both patient-specific (PS) and off-the-shelf (OTS). Twenty-two peer-reviewed articles and one book chapter were included, mainly comprising case reports and series focusing on PS titanium implants for complex spinal reconstructions due to neoplasia, infection, trauma, or degenerative diseases. Findings suggest that 3DP spinal implants have considerable utility in managing complex spine pathologies and have been used safely with positive outcomes reported by surgeons and patients. However, conclusions are tentative due to short follow-up periods and a lack of high-powered studies. The study recommends more standardized reporting of clinical, radiographic, and biomechanical outcomes to strengthen future research." ,
        url: "https://link.springer.com/article/10.1007/s00586-019-06236-2",
    },
    { 
        title: "3-dimensional printing for anterior cervical surgery: a review", 
        abstract: "Degenerative changes and pathologies of the cervical spine can compress neurological structures and alter anatomy, necessitating surgical decompression and stabilization. Three-dimensional printing (3DP) has been applied to create accurate patient-specific models and implants, offering benefits in the complex anatomy of the cervical spine. This review evaluates the current applications of 3DP for cervical spinal implants, including patient-specific implants (PSIs) and off-the-shelf (OTS) 3D-printed devices. It discusses suitable materials for spinal implants and future prospects in the field. The review concludes with recommendations for future studies to assess the efficacy and safety of 3DP in cervical spinal implants, aiming to guide clinical practice and research in this evolving area." ,
        url: "https://pmc.ncbi.nlm.nih.gov/articles/PMC6330582/",
    },
    { 
        title: "C3-C5 Chordoma Resection and Reconstruction with a Three-Dimensional Printed Titanium Patient-Specific Implant", 
        abstract: "A 45-year-old man with a chordoma causing destruction of the C3-C5 vertebrae underwent gross total tumor resection and reconstruction using a custom three-dimensional printed titanium implant. The implant was designed to reduce reconstruction time and improve outcomes. Postoperatively, the patient showed significant clinical improvement, which persisted at 17 months follow-up, with no tumor recurrence and minimal implant subsidence. The study concludes that computer-aided preoperative planning with 3D-printed biomodels and custom implants can result in efficient reconstruction with good clinical and radiographic outcomes. However, it notes that metal implants can cause significant imaging artifacts in patients requiring postoperative radiotherapy or MRI." ,
        url: "https://www.sciencedirect.com/science/article/abs/pii/S1878875019330177",
    },
    { 
        title: "Revision of a Failed C5-7 Corpectomy Complicated by Esophageal Fistula Using a 3-Dimensional–Printed Zero-Profile Patient-Specific Implant", 
        abstract: "Esophageal fistulae are serious complications of anterior cervical surgery, often related to hardware issues. This case reports the first use of a three-dimensional printed zero-profile patient-specific implant (PSI) to manage implant failure with migration and a secondary esophageal fistula. A 68-year-old woman with prior cervical surgery experienced anterior plate displacement leading to pseudoarthrosis and an esophageal fistula. A 3D-printed zero-profile PSI was designed and implanted during revision surgery to aid recovery and prevent recurrence. One month postoperatively, the patient showed significant improvement, with resolution of the esophageal fistula and optimal implant placement observed radiographically. The study suggests that zero-profile 3D-printed PSIs may help prevent complications like postoperative dysphagia and esophageal fistulae in anterior cervical surgery, though further research is needed." ,
        url: "https://www.sciencedirect.com/science/article/abs/pii/S1878875021005532",
    },
    { 
        title: "3D printed anatomical (bio) models in spine surgery: clinical benefits and value to health care providers", 
        abstract: "The clinical applications of three-dimensional printing (3DP) have expanded rapidly, particularly in spine surgery. This manuscript focuses on the use of anatomical biomodels in spinal clinical practice. These 3D-printed models include translucent and colored representations to aid in identifying the extent of pathologies like bone tumors, dynamic models for implant trial and preoperative sizing, and disassemblable models to simulate surgical procedures. Biomodels can reduce surgical risks by decreasing operation time, minimizing unexpected anatomical challenges, and improving preoperative planning. However, inaccurate biomodels can increase risks. A cost-benefit analysis indicates that biomodels can reduce healthcare costs if they decrease operating room time by 14 minutes or more. Collaboration between clinicians and engineers is crucial to provide advanced care for spinal patients." ,
        url: "https://pmc.ncbi.nlm.nih.gov/articles/PMC6989927/",
    },
    { 
        title: "Development of a customized interbody fusion device for treatment of canine disc-associated cervical spondylomyelopathy", 
        abstract: "This study aimed to design, manufacture, and implant customized three-dimensional printed intervertebral spacers to treat disc-associated cervical spondylomyelopathy (DACSM) in two dogs. Customized titanium alloy interbody devices were implanted, and advanced imaging assessed implant movement, subsidence, and interbody fusion at 0, 2, and 6 months postoperatively. By 6 months, progressive fusion was observed across the affected intervertebral spaces, with minimal implant migration and subsidence. Both dogs showed clinical improvement in neurological status. The customized interbody devices effectively restored intervertebral space, facilitated interbody fusion, and resolved clinical signs. The report demonstrates that collaboration between clinicians and engineers using advanced manufacturing technologies can enhance the standard of care for patients with DACSM." ,
        url: "https://pubmed.ncbi.nlm.nih.gov/30646415/",
    },
  ];

  const handleMouseEnter = (index) => {
    if (!isMobileView) {
      setHoveredIndex(index);
      setAbstract(articles[index].abstract);

      if (containerRef.current) {
        const abstractElement = containerRef.current.querySelector(`.${classes.abstractSection}`);
        const articleLinkElement = containerRef.current.querySelectorAll(`.${classes.articleLink}`)[index];

        if (abstractElement && articleLinkElement) {
          const abstractStart = abstractElement.getBoundingClientRect().left;
          const linkEnd = articleLinkElement.getBoundingClientRect().right;
          const arrowWidth = abstractStart - linkEnd;
          setArrowWidths((prevWidths) => ({ ...prevWidths, [index]: arrowWidth }));
        }
      }
    }
  };

  const handleMouseLeave = () => {
    if (!isMobileView) {
      setHoveredIndex(null);
      setAbstract('');
      setArrowWidths({});
    }
  };

  // Update isMobileView based on window width
  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 1020);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classes.container} ref={containerRef}>
      <div className={classes.articleList}>
        {articles.map((article, index) => (
          <div
            key={index}
            className={classes.articleItem}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div className={classes.articleTextContainer}>
              <a href={article.url} className={classes.articleLink} target="_blank" rel="noopener noreferrer">
                {article.title}
              </a>
              {!isMobileView && (
                <div
                  className={classes.arrow}
                  style={{
                    width: arrowWidths[index] || '0px', // Expand width only on hover
                    left: '101%', // Start arrow at the end of the link text
                  }}
                ></div>
              )}
            </div>
          </div>
        ))}
      </div>

      {!isMobileView && (
        <div
          className={`${classes.abstractSection} ${
            hoveredIndex !== null ? classes.abstractSectionVisible : ''
          }`}
        >
          <h2 className={classes.abstractTitle}>Summary</h2>
          <p className={classes.abstractText}>{abstract}</p>
        </div>
      )}
    </div>
  );
};

export default PublishedArticles;
