import React, { createContext, useContext, useState } from 'react';

const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {
  const [hasShownModal, setHasShownModal] = useState(false);
  // Define additional states as needed
  // const [anotherState, setAnotherState] = useState(someInitialValue);

  return (
    <AppStateContext.Provider value={{ hasShownModal, setHasShownModal /*, anotherState, setAnotherState */ }}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => useContext(AppStateContext);