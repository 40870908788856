import React from 'react';
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { YellowBtnContained } from "../components/YellowBtn";
import ScrollPsiZoom from "../components/ScrollPsiZoom";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import "../styles/Home.css"

import { useTypographyStyles, useLayoutStyles } from '../styles';

/**
 * Landing page
 * @returns
 */
export default function Home() { 
  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const navigate = useNavigate();

  const mediumViewport = useMediaQuery("(min-width:700px)");

  return (
    <>
      <div className="bgImageContainer">
        <div className={`imageOverlayText ${typogClasses.whiteText}`}>
          <div className="header1">
            Achieve exceptional results<br/>
          </div>
          <div className="header2">
            with our patient specific approach
          </div>
          {mediumViewport && (
            <YellowBtnContained
              style={{ width: '200px', marginTop: '30px' }}
              onClick={() => navigate('/Contact-us')}
            >
              Learn more
            </YellowBtnContained>
          )}
        </div>
        <img
          src="https://d3fcjysp85tqc8.cloudfront.net/home_page/home_banner.webp"
          alt="Home Banner"
          className="homeImage"
        />
      </div>
    
    {/* <div style={{backgroundColor: '#ebebeb', paddingTop: "35px", paddingBottom: "30px"}}> */}
    <div style={{backgroundColor: '#ffffff', paddingTop: "30px", paddingBottom: "30px"}}>
      <div className="sectionContainer">
        <div className={`${typogClasses.subHeading} ${typogClasses.textCentre}`}>What We Do</div>
        <div className="textCentreContainer">
          <div style={{maxWidth: "1000px", textAlign: "justify", marginLeft: '10px', marginRight: '10px', marginTop: '20px'}} className={` ${typogClasses.textCentre} ${typogClasses.paragraphCustom}`}>
          We produce spinal fusion implants that fit each patient’s <b>unique</b> anatomy and treat their specific pathology. 
          </div>
        </div>
        <ScrollPsiZoom />
      </div>
    </div>
    <div style={{backgroundColor: '#ebebeb', paddingTop: "20px", paddingBottom: "30px"}}>
      <div className="sectionContainer">
      <div className={`${typogClasses.subHeading} ${typogClasses.textCentre}`}><b>Benefits</b></div>
        <div className={`${layoutClasses.splitContainer} benefitsContainer`}  style={{ marginBottom: "60px", marginTop: "25px" }}>
          <div className={layoutClasses.column} style={{ alignItems: 'center', flex: 1 }}>
            <img 
              src="https://d3fcjysp85tqc8.cloudfront.net/home_page/d_correction.webp" 
              alt="Video Placeholder"
              className="benefitsImages"
              />
              <div className={`${typogClasses.weakSubHeading} ${typogClasses.textLeftCentre}`}>
                <b>Achieve desired corrections</b>
              </div>
              <div style={{textAlign: "justify", marginBottom: "30px"}} className={`${typogClasses.paragraphXSmall} ${typogClasses.textJustifyCentre}`}>
              Customisable implant angles allow surgeons to specify corrections based on patient anatomy.
              </div>
          </div>
          <div className={layoutClasses.column} style={{ alignItems: 'center', flex: 1}}>
            <img 
              src="https://d3fcjysp85tqc8.cloudfront.net/home_page/d_risk.webp" 
              alt="Video Placeholder"
              className="benefitsImages"
              // style={{maxWidth: "230px"}}
              />
              <div className={`${typogClasses.weakSubHeading} ${typogClasses.textLeftCentre}`}>
                <b>Reduced risk</b>
              </div>
              <div style={{textAlign: "justify", marginBottom: "30px"}} className={`${typogClasses.paragraphXSmall} ${typogClasses.textJustifyCentre}`}>
              Thorough pre-planning minimises surgical risks, reducing complications during and after surgery.
              </div>
          </div>
          <div className={layoutClasses.column} style={{ alignItems: 'center', flex: 1 }}>
            <img 
              src="https://d3fcjysp85tqc8.cloudfront.net/home_page/d_stable.webp" 
              alt="Video Placeholder"
              className="benefitsImages"
              />
              <div className={`${typogClasses.weakSubHeading} ${typogClasses.textLeftCentre}`}>
                <b>Stable foundation</b>
              </div>
              <div style={{textAlign: "justify"}} className={`${typogClasses.paragraphXSmall} ${typogClasses.textJustifyCentre}`}>
              Increased vertebral endplate contact area enhances stability and accelerates fusion by providing a solid foundation for bone integration and load distribution.
              </div>
          </div>
        </div>
      </div>
    </div>

    <div style={{backgroundColor: '#ffffff'}}>
      <div className="sectionContainer" style={{paddingBottom: "40px", paddingTop: "40px"}}>
        <div className={`${typogClasses.subHeading} ${typogClasses.textCentre}`}>Patient <b>Testimonials</b></div>
      <Swiper
          className="swiperTestimony"
          modules={[Navigation, Pagination, A11y]}
          loop={true}
          navigation
          pagination={{ clickable: true }}
          // autoplay={{
          //   delay: 10000,
          //   disableOnInteraction: false,
          // }}
        >
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className="swiperTestimonyContainer">
              <div className="testimonyText">
                <i>“I'm very happy with my surgeons. I now have zero pain and feel relief from my previous 10/10 excruciating 
                and relentless pain.”</i>
                <div style={{marginTop: "14px"}}>
                  LLIF - September 2022
                </div>
              </div>
              { mediumViewport && <>
                <div className="testimonyText">
                  <i>“I feel like a new woman. I don't rely on any painkillers now.  I'm very grateful for the technology and what 3DMorphic was able to manufacture for me. I have been telling everyone about my personalized spinal devices and the experience with my surgeon.”</i>
                  <div style={{marginTop: "14px"}}>
                    Double level ALIF - September 2020
                  </div>
                </div>
                <div className="testimonyText">
                  <i>“The pain was sickening and now is gone.”</i>
                  <div style={{marginTop: "14px"}}>
                    LLIF Surgery - March 2022
                  </div>
                </div>
              </>}
            </div>
          </SwiperSlide>
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className="swiperTestimonyContainer">
              { mediumViewport &&
                <div className="testimonyText">
                  <i>“Before the surgery I found it hard to move and straighten up. I was struggling to close my hands, and my arms would go weak. After surgery, I regained immediate control of my hands and felt so much better. My recovery went very well, migraines are gone, and I now have a full range of motion.  I would describe it as the best experience and my neck feels great. I had a non-patient-specific fusion surgery before, and I significantly struggled with my recovery that time.”</i>
                  <div style={{marginTop: "14px"}}>
                    ACDF - July 2021
                  </div>
                </div>
              }
              <div className="testimonyText">
                <i>“I'm happy as can be. I'm now largely free of sciatica. I can now do 15-18km of walking in a day.”</i>
                <div style={{marginTop: "14px"}}>
                  ALIF - May 2021
                </div>
              </div>
              { mediumViewport &&
                <div className="testimonyText">
                  <i>“I feel like my pain went from a 10 to a 0 immediately. I'm ecstatic with my results, I would describe them as 
                  brilliant. Before the surgery I couldn't sit, stand or walk at all. Now my life has completely turned around. I'm 
                  regularly doing physiotherapy and I'm back to most of my usual activities. I have no pain at all.”</i>
                  <div style={{marginTop: "14px"}}>
                    Triple level ALIF and LLIF - November 2022
                  </div>
                </div>
              }
            </div>
          </SwiperSlide>
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className="swiperTestimonyContainer">
              { mediumViewport &&
                <div className="testimonyText">
                  <i>“My experience has been brilliant.”</i> 
                  <div style={{marginTop: "14px"}}>
                    ACDF - September 2023
                  </div>
                </div>
              }
              <div className="testimonyText">
                <i>“I'm very happy of the progress of my recovery. I have a noticeable improvement and generally have no back pain 
                now. I'm back at work and don't take any medication. I'm also back to most of the other usual activities that I 
                enjoy.” </i>
                <div style={{marginTop: "14px"}}>
                  ALIF - August 2022
                </div>
              </div>
              { mediumViewport &&
                <div className="testimonyText">
                  <i>“Can't thank you enough for the technology and my outcome.” </i>
                  <div style={{marginTop: "14px"}}>
                    ALIF - June 2021
                  </div>
                </div>
              }
            </div>
          </SwiperSlide>
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className="swiperTestimonyContainer">
              <div className="testimonyText">
                <i>“Before the surgery I wasn't able to drive around or do any activity because of the pain. I have improved dramatically 
                  and now swim 2km a day, walk up to 8km and have no issue with my mobility and driving around.” </i> 
                <div style={{marginTop: "14px"}}>
                  LLIF - November 2022
                </div>
              </div>
              { mediumViewport && <>
                <div className="testimonyText">
                  <i>“I'm extremely happy with the results of my surgery. My pain and symptoms were severally affecting my mobility 
                    before. I couldn't walk or stand for more than 10-15min. Since surgery I have recovered very well and now regularly 
                    take 3+ hours bushwalks. I do experience some occasional pain but unrelated to the operated level. The patient specific 
                    surgery was absolutely life changing.” </i>
                  <div style={{marginTop: "14px"}}>
                    ALIF - September 2021
                  </div>
                </div>
                <div className="testimonyText">
                  <i>“I'm pain free and very impressed with the implant and the designers.”</i>
                  <div style={{marginTop: "14px"}}>
                    ALIF - September 2020
                  </div>
                </div>
              </>}
            </div>
          </SwiperSlide>
          <SwiperSlide className={layoutClasses.centreItem}>
            <div className="swiperTestimonyContainer">
              { mediumViewport &&
                <div className="testimonyText">
                  <i>“I used to have issues with numbness, pins and needles, balance and movement. Now I feel infinitely better. I'm basically pain free and very happy with the results of my surgery.” </i> 
                  <div style={{marginTop: "14px"}}>
                    ACDF - November 2020
                  </div>
                </div>
              }
              <div className="testimonyText">
                <i>“I'm back to my normal day activities with no pain or issues in movement. I'm amazed at the results and could not have asked for a better result.” </i>
                <div style={{marginTop: "14px"}}>
                  Triple level ACDF - February 2022
                </div>
              </div>
              { mediumViewport &&
                <div className="testimonyText">
                  <i>“I'm very happy with my results. Many other people in my family have also needed to get spine surgeries, and my success goes 
                    against the results of other people in my family.”</i>
                  <div style={{marginTop: "14px"}}>
                    ALIF - June 2020
                  </div>
                </div>
              }
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>

    <div style={{backgroundColor: '#ebebeb'}}>
      <div className={layoutClasses.sectionContainer} style={{maxWidth: '1200px', paddingBottom: "20px", paddingTop: "40px"}}>
        <div className={layoutClasses.splitContainer} style={{ alignItems: 'center', marginBottom: 30, marginTop: "30px", gap: "40px" }}>
          <div style={{ display: 'flex', gap: "40px" }}>
            <img 
            src="https://d3fcjysp85tqc8.cloudfront.net/artgl.webp" 
            alt="ARTG logo"
            className={layoutClasses.artgLogo}
            />
          { !mediumViewport &&
            <img 
            src="https://d3fcjysp85tqc8.cloudfront.net/home_page/am-logo.webp" 
            alt="Aussie made logo"
            className={layoutClasses.artgLogo}
            />
          }
          </div>
          <div className={`${typogClasses.paragraphBig} ${typogClasses.textJustifyCentre}`} style={{ marginLeft: 0, marginRight: 0}}>
          3DMorphic is Australia's first ARTG listed manufacturer of patient specific spinal implants and is dedicated to producing personalised surgical solutions that improve patient quality of life. 
          </div>
          { mediumViewport && <img 
            src="https://d3fcjysp85tqc8.cloudfront.net/home_page/am-logo.webp" 
            alt="Aussie made logo"
            className={layoutClasses.artgLogo}
            />}
        </div>
        </div>
      <div className='patientInMind'>
        <div className="header3" style={{textAlign: "center"}}>
          Have a patient in mind?
        </div>
        <YellowBtnContained style={{width: "200px", marginTop: "30px"}} onClick={() => navigate("/Contact-us")}>Schedule a meeting</YellowBtnContained>
      </div>
    </div>
  </>
  );
}


