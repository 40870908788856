import React, { useState } from 'react';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import '../styles/RotatingKeenSlider.css';

// Import Material-UI components
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CustomKeenSlider = ({ slides, arrowColor = 'black' }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderReady, setSliderReady] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "snap",
    slides: {
      origin: "center",
      perView: 2.8,
      spacing: 35,
    },
    breakpoints: {
      "(min-width: 20px)": {
        slides: {
          origin: "center",
          perView: 1,
          spacing: 15,
        },
      },
      "(min-width: 768px)": {
        slides: {
          origin: "center",
          perView: 1.4,
          spacing: 20,
        },
      },
      "(min-width: 1200px)": {
        slides: {
          origin: "center",
          perView: 1.8,
          spacing: 20,
        },
      },
      "(min-width: 1500px)": {
        slides: {
          origin: "center",
          perView: 2,
          spacing: 20,
        },
      },
    },
    created(s) {
      setCurrentSlide(s.track.details.rel);
      setSliderReady(true);
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
  });

  const getSlideClass = (index) => {
    if (!instanceRef.current) return '';

    const relativePosition = index - currentSlide;

    switch (relativePosition) {
      case 0:
        return 'slider-item--active';
      case -1:
        return 'slider-item--prev';
      case -2:
        return 'slider-item--prev-2';
      case 1:
        return 'slider-item--next';
      case 2:
        return 'slider-item--next-2';
      default:
        return '';
    }
  };

  const goToSlide = (index) => {
    instanceRef.current?.moveToIdx(index);
    setCurrentSlide(index);
  };

  // Handlers for arrow buttons
  const handlePrev = () => {
    instanceRef.current?.prev();
  };

  const handleNext = () => {
    instanceRef.current?.next();
  };

  return (
    <div className="slider-container">
      <div className="slider-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {slides.map((renderSlide, index) => (
            <div
              key={index}
              className={`keen-slider__slide slider-item ${getSlideClass(index)}`}
            >
              {/* Render each slide content, passing isActive */}
              <div className="slide-content">
                {renderSlide(index === currentSlide)}
              </div>
            </div>
          ))}
        </div>

        {/* Left Arrow */}
        {sliderReady && instanceRef.current && (
          <IconButton
            onClick={handlePrev}
            disabled={currentSlide === 0}
            className="arrow-button arrow-button--left"
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}

        {/* Right Arrow */}
        {sliderReady && instanceRef.current && (
          <IconButton
            onClick={handleNext}
            disabled={
              currentSlide ===
              instanceRef.current.track.details.slides.length - 1
            }
            className="arrow-button arrow-button--right"
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </div>

      {/* Pagination bullets */}
      <div className="slider-pagination">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`slider-pagination-bullet ${
              currentSlide === index ? 'active' : ''
            }`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomKeenSlider;
