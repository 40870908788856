import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stack, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Email, Phone, LinkedIn, YouTube  } from '@mui/icons-material';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#000000",
    color: "white",
    padding: '55px 150px',
    [theme.breakpoints.down(820)]: {
      padding: '65px 45px',
    },
  },
  containerStack: {
    maxWidth: 1100,
    margin: "auto",
  },
  boxContainer: {
    flexGrow: 1,
    paddingLeft: 25,
    [theme.breakpoints.up(600)]: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 5,
    },
  },
  companyInfo: {
    textAlign: "left"
  },
  headerText: {
    fontWeight: 'bold !important',
    marginBottom: '7px !important',
  },
  navigation: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  link: {
    cursor:"pointer",
  },
  icon: {
    cursor:"pointer",
    paddingTop: 13,
    paddingRight: 18,
  },
  
  white: {
    fill: 'white',
  },
  navBox: {
    height: '50%',
    [theme.breakpoints.up(600)]: {
      maxWidth: "50%",
    },
  }
}));

export default function Footer() {
  const classes = useStyles();
  const navigate = useNavigate();

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <footer className={classes.root}>
      <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} className={classes.containerStack}>
        <div className={classes.boxContainer}>
          <Stack spacing={0.4} direction="column">
            <Typography variant="h6" className={classes.headerText}>
              CONTACT
            </Typography>
            <Typography variant="subtitle2">
              <Link color="inherit" href="mailto:info@3dmorphic.com" underline="none">
                info@3dmorphic.com 
              </Link>
            </Typography>
            <Typography variant="subtitle2">
              3DMorphic Pty Ltd
            </Typography>
            <Typography variant="subtitle2">
              Unit 1/38 Raymond Ave Matraville
            </Typography>
            <Typography variant="subtitle2">
              NSW 2036 Australia
            </Typography>
            <div>
              <Email className={classes.icon} onClick={() => {window.location.href = 'mailto:info@3dmorphic.com';}}/>
              <Phone className={classes.icon} onClick={() => {window.location.href = 'tel:+61 424 651 478';}}/>
              <LinkedIn className={classes.icon} onClick={() => openInNewTab('https://www.linkedin.com/company/3dmorphic/')}/>
              <YouTube className={classes.icon} onClick={() => openInNewTab('https://www.youtube.com/channel/UCYOWHwMAdTs_g2Uuq0Hbyrw')}/>
              <svg className={classes.icon} onClick={() => openInNewTab('https://twitter.com/3dmorphic')} xmlns="http://www.w3.org/2000/svg" height="21" width="21" viewBox="0 0 512 512"><path className={classes.white} d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
            </div>
          </Stack>
        </div>
        <div className={classes.boxContainer}>
          <Stack spacing={0.4} direction="column">
            <Typography variant="h6" className={classes.headerText}>
              ABOUT
            </Typography>
            <Typography className={classes.link} variant="subtitle2" onClick={() => navigate("/Difference")}>
              Difference
            </Typography>
            <Typography className={classes.link} variant="subtitle2" onClick={() => navigate("/Products")}>
              Products
            </Typography>
            <Typography className={classes.link} variant="subtitle2" onClick={() => navigate("/Services")}>
              Services
            </Typography>
          </Stack>
        </div>
        <div className={classes.boxContainer}>
          <Stack spacing={0.4} direction="column">
            <Typography variant="h6" className={classes.headerText}>
              DETAILS
            </Typography>
            <Typography className={classes.link} variant="subtitle2" onClick={() => navigate("/Media")}>
              Media
            </Typography>
            <Typography className={classes.link} variant="subtitle2" onClick={() => navigate("/Contact-us")}>
              Contact Us
            </Typography>
          </Stack>
        </div>
      </Stack>
    </footer>
  );
}
