const smoothScrollTo = (target, duration) => {
  const start = window.scrollY;
  const change = target - start;
  let startTime = null;

  if (duration < 0) {
    duration = Math.abs(change) / 4 + 300;
  }

  // Easing function for smoothness
  const ease = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  const animation = (currentTime) => {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, start, change, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  };

  requestAnimationFrame(animation);
};

// Usage in React
const ScrollTo = (ref, offset = -1, duration = -1000) => { // default duration 1000ms
  if (ref.current) {
    const screenWidth = window.innerWidth;
    const appbarHeight = (offset >= 0) ? offset : (screenWidth <= 920) ? 20 : 120;
    const target = ref.current.offsetTop - appbarHeight;
    smoothScrollTo(target, duration);
  }
};

export default ScrollTo;
