import { makeStyles } from '@material-ui/core/styles';
import colours from './colours';

const useLayoutStyles = makeStyles((theme) => ({

  globalContainer: {
    maxWidth: '100%', // Changed from 1400px to 100%
    marginLeft: 0, // Keep it 0
    marginRight: 0, // Keep it 0
    padding: 0, // Keep it 0
  },

  TitleInfoContainer: {
    maxWidth: '1100px',
    margin: 'auto',
  },
  infoItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },

  chessGreyWhiteBackground: {
    background: 'linear-gradient(to right, #F6F6F6 50%, white 50%)',
    [theme.breakpoints.down(920)]: {
      background: 'linear-gradient(to right, #F6F6F6 50%, #F6F6F6 50%)'
    },
    marginBottom: 0, 
    marginTop: 0, 
  },

  chessWhiteGreyBackground: {
    background: 'linear-gradient(to right, white 50%, #F6F6F6 50%)',  
    [theme.breakpoints.down(920)]: {
        background: 'linear-gradient(to right, white 50%, white 50%)'
    },
    color: 'black',
    marginBottom: 0, 
    marginTop: 0, 
  },

  lightgreyContainer: {
    backgroundColor: "#F6F6F6",
  },

  greyContainer: {
    backgroundColor: "#53595D",
  },

  topBottomMargin: {
    marginTop: '20px',
    marginBottom: '20px',
  },

  topMargin: {
    marginTop: '50px',
  },

  leftRightMargin: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  
  topBottomPadding: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  
  leftRightPadding: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },

  flex1: {
    flex: 1,
  },
  
  paddLeft: {
    paddingLeft: 10,
  },

  paddRight: {
    paddingRight: 10,
  },

  yellowContainer: {
    backgroundColor: "#ffcc2f",
  },

  centreItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  splitContainer: {
    width: '95%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
    [theme.breakpoints.down(920)]: {
      flexDirection: 'column',
      gap: "30px",
    },
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
  },

  width_90_50: {
    width: '90%',
    [theme.breakpoints.up(700)]: {
      width: '50%',
    },
  },



  // #########################################
  // PRODUCTS PAGE SPECIFIC
  // #########################################
  // PageContentContainer: {
  sectionContainer: {
    maxWidth: '1400px',
    margin: 'auto',
    padding: theme.spacing(2),
    // paddingTop: 0,
  },
  frameContainer: {
    display: 'flex',
    flexDirection: 'column', // Changed to column
    justifyContent: 'space-between', // Adjusts space distribution
    backgroundColor: colours.FrameLightGrey,
    '&:hover': {
      backgroundColor: colours.FrameHoverGrey,
    },
    cursor: 'pointer',
    textAlign: 'center',
    overflow: 'hidden',
    height: '100%'
  },
  frameContent: {
    flexGrow: 1,
    textAlign: 'center',
    overflow: 'hidden',
  },
  frameImageContainer: { // New class for image container
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center', // Centers the image vertically
    height: '85%',
    marginTop: '-9%', 
  },

  contentsLeft: {
    justifyContent: 'start',
  },
  contentsRight: {
    justifyContent: 'end',
  },

  frameImage: {
    maxWidth: '80%',
    maxHeight: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  frameTitleContainer: {
    display: 'flex',
    alignItems: 'center', // Centers content vertically
    justifyContent: 'center', // Centers content horizontally
    height: '120px', 
    marginTop: theme.spacing(4),
  },
  imageTextImageSectionContainer: {
    maxWidth: '1300px',
    margin: 'auto',
    padding: theme.spacing(1),
  },
  imageTextImageSection: {
    textAlign: 'justify',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(10),
    marginBot: theme.spacing(10),
    [theme.breakpoints.down('sm')]: { // Adjust the breakpoint as needed
      flexDirection: 'column',
    },
  },
  halfSection: {
    width: '50%',
    height: '100%',
    display: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '84%',
    },
  },
  rightImageBox: {
    width: '40%',
    height: 'auto',
    margin: '0 auto', // Center the box
  },
  rightImage: {
    width: '100%',
    maxHeight: '25vh',
    height: 'auto',
    objectFit: 'contain',
  },
  leftImage: {
    width: '80%',
    height: 'auto',
    objectFit: 'contain',
  },


  // #########################################
  // SERVICES PAGE SPECIFIC
  // #########################################

  contentContainer: {
    margin: '0 auto',
    padding: `0 15px`, // Adjust the horizontal padding as needed
    maxWidth: '1200px', // Adjust the max width as needed
    position: 'relative', // This will be the anchor for absolute positioning
    paddingTop: 1, // Remove top padding
    paddingBottom: 2, // Remove bottom padding
    height: 'auto', // Adjust as needed
    fontSize: '1.8rem',
  },


  // #########################################
  // DIFFERENCE PAGE SPECIFIC
  // #########################################







  // #########################################
  // HOME PAGE SPECIFIC
  // #########################################

  // rightVideoContainer: {
  //   width: '50%',
  //   textAlign: 'center',
  //   // marginLeft: theme.spacing(-10),
  //   [theme.breakpoints.down('sm')]: {
  //     marginLeft: 0,
  //     width: '90%',
  //   },
  // },
  // videoPlaceholder: {
  //   width: '100%',
  //   height: 'auto',
  //   objectFit: 'cover',
  // },

  artgLogo: {
    width: '110px',
    height: 'auto',
    objectFit: 'cover',
    [theme.breakpoints.up(600)]: {
      width: '140px',
    },
    [theme.breakpoints.up(960)]: {
      width: '180px',
    },
  },

  homeMainTitle: {
    textAlign: 'center',
    fontSize: '2.8rem',
    fontWeight: 'bold',
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up(960)]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up(1100)]: {
      fontSize: '3.5rem',
    },
  },



}));

export default useLayoutStyles;