import { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { Stack } from "@mui/material";
import YellowBtn from "../components/YellowBtn";
import ScrollTo from "../components/ScrollTo";
import ImageTextSection from "../components/ImageTextSection"; 
import { useTypographyStyles, useLayoutStyles } from '../styles';

const useStyles = makeStyles(theme => ({
  globalContainer: {
    maxWidth: '100%',
    margin: 2,
    padding: 2,
    overflowX: 'hidden',
  },

  contentContainer: {
    margin: '0 auto',
    padding: `2 8px`,
    maxWidth: '1200px',
    position: 'relative',
    paddingTop: 1,
    paddingBottom:20,
    height: 'auto',
    fontSize: '1.8rem',
  },

  whiteGreyBackground: {
    background: 'linear-gradient(to right, white 50%, #F6F6F6 50%)',  
    [theme.breakpoints.down(920)]: {
      background: 'linear-gradient(to right, white 50%, white 50%)'
    },
    color: 'black',
    marginBottom: 0, 
    marginTop: 0, 
  },

  greyWhiteBackground: {
    background: 'linear-gradient(to right, #F6F6F6 50%, white 50%)',
    [theme.breakpoints.down(920)]: {
      background: 'linear-gradient(to right, #F6F6F6 50%, #F6F6F6 50%)'
    },
    color: 'black',
    marginBottom: 0, 
    marginTop: 0, 
  },

  mainHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
    fontSize: '2.85rem',
    fontWeight: 'normal',
    color: '#53595D',
    flexDirection: "column",
    
  },

  InfoText: {
    fontSize: '1.3rem',
    color: '#53595D',
    lineHeight: 1.5,
    margin: "20px 12px",
    maxWidth: 1200,
    [theme.breakpoints.down('800')]: {
      fontSize: '1.1rem',
    },
  },

  btnContainer: {
    margin: '35px 5px',
    alignItems: "center",
    marginBottom: 30,
    [theme.breakpoints.up(920)]: {
      marginTop: '50px',
    },
  },
}));

export default function Difference() {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const typogClasses = useTypographyStyles();

  const ref_ABF = useRef(null);
  const ref_CA = useRef(null);
  const ref_FTM = useRef(null);
  const ref_SP = useRef(null);
  const ref_LS = useRef(null);

  return (
    <div className={classes.globalContainer}>
      <div className={classes.mainHeader}>
        {/* <p className={classes.headerParagraph}> */}
        <div className={`${typogClasses.pageTitle}`}>
          <p>The 3DMorphic <b>Difference</b></p>
          {/* </p> */}
        </div>
        <div className={classes.subheaderParagraph}>
          <div className={classes.contentContainer}>
            Every patient and every surgery is unique, and as such, we believe customisation is key
          </div>
        </div>
        <div className={`${classes.InfoText} ${typogClasses.textJustifyCentre}`}>
          In spinal surgery, there is an intrinsic link between a good anatomical fit, achieving suitable alignment correction, and good clinical results. 3DMorphic patient specific devices are designed to have a better fit, providing a more even load distribution between the implant and patient anatomy. The increased surface area due to better fit promotes early onsite bone growth and stable fixation, which can result in a higher patient quality of life. ​
          Additionally, the patient specific nature of 3DMorphic devices provides the surgeons with the opportunity to have an input into the correction and alignment achieved by the cage. 3DMorphic implants require reduced intra-operative endplate remodeling, resulting in an even distribution of low strain in the endplate bone, more consistent results for patients, and simpler procedures for surgeons.
        </div>
      </div>

      <div className={classes.contentContainer}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 4 }} className={classes.btnContainer}>
          <YellowBtn onClick={() => ScrollTo(ref_ABF)}>A Better Fit</YellowBtn>
          <YellowBtn onClick={() => ScrollTo(ref_CA)}>Controlled Alignment</YellowBtn>
          <YellowBtn onClick={() => ScrollTo(ref_FTM)}>Fast to Manufacture</YellowBtn>
          <YellowBtn onClick={() => ScrollTo(ref_SP)}>Simple Procedures</YellowBtn>
          <YellowBtn onClick={() => ScrollTo(ref_LS)}>Even Loading</YellowBtn>
        </Stack>
      </div>
      

      <div className={classes.greyWhiteBackground} ref={ref_ABF}>
        <div className={classes.contentContainer}>
        <ImageTextSection
          title="A Better Fit"
          text="Our patient specific implants are engineered to fit with the patient's unique anatomy, ensuring immediate stability and promoting faster fusion. This snug fit ensures even load distribution and proximity between the implant and bone, setting the right conditions for bone fusion."
          imageUrl="https://d3fcjysp85tqc8.cloudfront.net/S1.webp"
          imageLeft={true}
          textStyle={{ textAlign: 'justify' }} 
          
        />
        </div>
      </div>
      
      <div className={classes.whiteGreyBackground} ref={ref_CA}>
        <div className={classes.contentContainer}>
        <ImageTextSection
          title="Controlled Alignment"
          text="Achieving anatomical alignment correction aids with pain relief and prevents adjacent segment disease, avoiding further complications."
          imageUrl="https://d3fcjysp85tqc8.cloudfront.net/S2.webp"
          imageLeft={false}
          textStyle={{ textAlign: 'justify' }} 
        />
        </div>
      </div>

      <div className={classes.greyWhiteBackground} ref={ref_FTM}>
        <div className={`${classes.contentContainer} ${classes.alignImageRight}`}>
        <ImageTextSection
          title="Fast to Manufacture"
          text="3DMorphic's vertically integrated manufacturing facility enables the rapid production of quality patient specific devices. We deliver implants for time sensitive, complex and general cases that seamlessly integrate with existing ordering systems."
          imageUrl="https://d3fcjysp85tqc8.cloudfront.net/S3.webp"
          imageLeft={true}
          textStyle={{ textAlign: 'justify' }} 
          hasDepthEffect={true}
        />
        </div>
      </div>

      <div className={classes.whiteGreyBackground} ref={ref_SP}>
       <div className={classes.contentContainer}>
        <ImageTextSection
          title="Simple Procedures"
          text="3DMorphic implants are designed to be implanted using existing surgical techniques."
          imageUrl="https://d3fcjysp85tqc8.cloudfront.net/S4.webp"
          imageLeft={false}
          imageClassName={classes.imageAlignLeft} 
          textStyle={{ textAlign: 'justify' }} 
          hasDepthEffect={true}
        />
        </div>
      </div>

      <div className={classes.greyWhiteBackground} ref={ref_LS}>
       <div className={classes.contentContainer}>
        <ImageTextSection
          title="Better Load Sharing"
          text="3DMorphic implants require minimal surgical remodelling of endplate bone compared to off-the-shelf devices as they are designed to fit the patient’s anatomy. This method of remodelling maintains the strong endplate bone, and results in an even distribution of low strain in the endplates adjacent to the implant, leading to consistent patient results."
          imageUrl="https://d3fcjysp85tqc8.cloudfront.net/S5c.webp"
          imageLeft={true}
          textStyle={{ textAlign: 'justify' }} 
        />
        </div>
      </div>
    </div>
  );
}