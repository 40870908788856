import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';

/**
 * Styling of an MUI Button to be used as a default button
 */
  // padding: 8px 15px;

const YellowBtn = styled(({ isActive, ...otherProps }) => <MuiButton {...otherProps} />)`
  font-family: "Open Sans", sans-serif;
  color: #464946;
  font-size: 15px !important;
  text-transform: none;
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
  border: 3px solid #ffc103;
  border-radius: 12px;
  width: 230px;
  &:hover {
    background-color: #ffc103;
    cursor: pointer;
  }

  @media (min-width: 600px) {
    font-size: 16px !important;
  }
  @media (max-width: 900px) {
    font-weight: bold;
  }
  @media (min-width: 900px) {
    font-size: 16px !important;
  }
  @media (min-width: 1400px) {
    font-size: 18px !important;
  }
`;

export const YellowBtnContained = styled(({ isActive, ...otherProps }) => <MuiButton {...otherProps} />)`
  font-family: "DM Sans", sans-serif;
  background-color: #ffc103;
  font-size: 18px !important;
  text-transform: none;
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
  border-radius: 13px;

  &:hover {
    background-color: #ffd92e;
    cursor: pointer;
  }
`;

export default YellowBtn;
