import { useState, useEffect } from "react";

import { Box, Modal, Fade, Backdrop, Container, Button, Stack, Typography, Grid, Paper, Card, CardMedia, CardContent } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


import { useNavigate } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  header: {
    backgroundImage: `url(${headerImage})`,
    // backgroundSize: 'cover',
    height: '150px',
    [theme.breakpoints.up(600)]: {
      height: '300px',
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  text: {
    textAlign: 'center',
    color: 'white'
  },
  gridContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 1300,
    margin: theme.spacing(2),
    [theme.breakpoints.up(800)]: {
      margin: theme.spacing(4),
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    height: "95%",
    cursor:"pointer",
    margin: theme.spacing(1),
    [theme.breakpoints.up(800)]: {
      margin: theme.spacing(3),
    },
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%'
  },
  cardImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  introContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%',
    maxWidth: '900px',
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    maxHeight: '80vh',
    overflowY: 'auto',
    [theme.breakpoints.down(600)]: {
      // width: '90%',
      padding: theme.spacing(2, 2, 3),
    },
  },
  modalImage: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(600)]: {
      marginBottom: theme.spacing(3),
    },
  },
}));


const headerImage = 'https://d3fcjysp85tqc8.cloudfront.net/SPinethingy.png';


export default function Who() {
  const classes = useStyles();

  const [selectedCard, setSelectedCard] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = (cardidx) => {
    setOpen(true);
    setSelectedCard(cardidx);
  }
  const handleClose = () => setOpen(false);

  return (
    <>
      <Paper className={classes.header}>
        <div className={classes.textContainer}>
          <Typography variant="h4" className={classes.text}>
            Welcome to our page!
          </Typography>
        </div>
      </Paper>
      <Box className={classes.introContainer}>
        <Typography variant="h4" >
          Welcome to 3DMorphic, where inovation is our life
        </Typography>
      </Box>
      <Box sx = {{ display: 'flex', justifyContent: 'center' }}>
        <Grid container className={classes.gridContainer}>
          {gridImages.map((profile, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card className={classes.card} onClick={() => handleOpen(index)}>
                <CardMedia
                  component="div"
                  className={classes.imageContainer}
                >
                  <img src={profile.img} className={classes.cardImage} alt={`Image ${index + 1}`} />
                </CardMedia>
                <CardContent>
                  <Typography variant="h4" component="h2">
                    {profile.name}
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {profile.position}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <Box sx={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon onClick={handleClose} sx={{ cursor:"pointer", mb: 1, fontSize: 30 }} />
          </Box>
          <img
            className={classes.modalImage}
            src={gridImages[selectedCard].img}
            alt="placeholder"
          />
          <Typography variant="h4" component="h2">
            {gridImages[selectedCard].name}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="h6" component="h2">
            {gridImages[selectedCard].position}
          </Typography>
          <Typography variant="body2" component="p">
            {gridImages[selectedCard].description}
          </Typography>
        </div>
      </Modal>
    </>
  );
};


const gridImages = [
  {
    name: "Dr Ron Weinberger",
    position: "Chairman",
    img: "https://3dmorphic.com/wp-content/uploads/2018/02/Untitled.png",
    description: "Ron Weinberger joined 3DMorphic initially to provide strategic advice to an early stage company. After working in academia and then in the biomedical sector, \
    Dr Weinberger joined Nanosonics Ltd (NAN) in August 2004. He was involved in the listing of NAN on the ASX in 2007. Ron was appointed as Executive Director and Board Member,\
    in July 2008 and held that Board role for 8 years.  He was appointed as CEO in May 2011 and then Managing Director which position he held for almost 3 years. As CEO, Ron grew \
    Nanosonics from a market capitalisation of approximately $100M to approximately $240M within 2 years in a challenging economic environment. He grew annual revenue from $2.3M \
    revenue to $14.9M over the same period and positioned NAN for near-term profitability. Today NAN has a market cap of about $800M. Ron has held multiple roles at NAN from heading \
    R&D/Regulatory, strategically building the IP portfolio and establishing USA operations. In commercialising the NAN product portfolio he developed distribution agreements with \
    multinational companies such as Toshiba in the UK and Miele Professional in Germany and was a signatory on the USA GE Healthcare distribution agreement. Ron was primarily responsible \
    for developing NAN's lead product, Trophon, which is now sold globally with regulatory approvals in major markets including the USA. Ron has led a team identifying unique opportunities \
    for NAN's new products portfolio, developing the fundamental technologies as well as the business cases supporting them. Dr Weinberger holds a PhD in medical biochemistry from the \
    University of Newcastle and is currently CEO and Managing Director of EMVision, a medical technology company developing new types of medical imaging. Dr Weinberger will continue to \
    provide strategic leadership capabilities to the 3DMorphic team including regulatory, intellectual property, and sales."
  },
  {
    name: "Dr William Parr",
    position: "Co-founder & Managing Director",
    img: "https://3dmorphic.com/wp-content/uploads/2021/08/IMG_3290-1489x1536.jpg",
    description: "Dr Parr holds a PhD in Computational Anatomy (University College London and the Natural History Museum, London), and has held Post-Doctoral Research Fellow positions at \
    the Surgical and Orthopaedic Research Laboratory at the University of New South Wales. Will came to Australia on an Endeavour Postdoctoral Research Fellowship Award from the Australian \
    Government in 2011. Will's principal areas of expertise are in 3D modelling of anatomy, both with respect to statistical 3D shape analysis and biomechanical analyses (e.g. joint axis \
    determination and bone kinematics, as well as Finite Element Analysis to predict stress and strain distributions), 3D reconstructions of anatomy (hard and soft tissue to create \
    musculoskeletal models) from CT and MRI data, and uses of additive manufacturing (3D printing) in medicine (3D printed anatomical models used during the planning of complex surgeries, \
    replication of clinical anatomy to test biomedical devices, and 3D printing of patient specific devices). Dr Parr has published peer reviewed scientific journal articles in top ranked \
    journals, and has been an invited speaker at leading international research conferences. He has secured significant competitive government and private competitive merit based funding."
  },
  {
    name: "John Ward",
    position: "Engineer",
    img: "https://d3fcjysp85tqc8.cloudfront.net/JohnWard.png",
    description: "John is a graduate engineer, having recently finished a Bachelor of Mechatronic Engineering with a Master's in Biomedical Engineering at the University of New South Wales. \
    He has a background in orthopaedic research being a lab assistant at the Surgical and Orthopaedic Research Laboratories in the Prince of Wales Hospital. There he was involved in histology, \
    mechanical testing, and various other laboratory activities. He joined 3DMorphic in early June 2020, using his experience in orthopedics to create biomodels and assist in device manufacturing."
  },
  {
    name: "Adam Schuman",
    position: "Engineer",
    img: "https://3dmorphic.com/wp-content/uploads/2021/08/IMG_3287-removebg-preview-150x150.png",
    description: "Adam holds a dual degree in Mechanical Engineering (Honours) and Computer Science from the University of New South Wales. With a solid background in software development, acquired \
    through his experiences at Freelancer.com and Escrow.com, as well as his academic studies, Adam has leveraged his expertise to develop advanced 3D modeling software aimed at improving the device \
    manufacturing process."
  },
  {
    name: "Chloe Amaro",
    position: "Quality Assurance",
    img: "https://3dmorphic.com/wp-content/uploads/2022/03/CHLOE-4.png",
    description: "Chloe holds a Bachelor of Biomedical Engineering (Honours) and Bachelor of Creative Intelligence and Innovation from the University of Technology, Sydney. She has experience in product \
    research and 3D cellular scaffold design through two-photon polymerisation. Chloe joined 3DMorphic in September 2021, utilising her transdisciplinary background to develop the 3DMorphic Technical File, \
    manage and enhance the Quality System and communicate with regulatory authorities."
  },
  {
    name: "Jess Wind",
    position: "Quality Assurance",
    img: "https://3dmorphic.com/wp-content/uploads/2022/03/jess3.png",
    description: "Jessica holds a Bachelor of Mechanical Engineering (Honours), and Bachelor of Creative Intelligence and Innovation from the University of Technology, Sydney. She has experience working on \
    multiple biomedical projects including designing and prototyping both a mechanical braking system, platform and structure for a platform-ankle rehabilitation robot, and 3D-printed patient specific lower \
    limbs prosthetics, with an advanced heating and cooling system. She joined 3DMorphic in November 2021, and in conjunction with Chloe, manages, maintains and enhances the Quality Management System to ensure \
    that it is up to date with regulatory requirements. "
  },
  {
    name: "Robert Belter",
    position: "Production Engineer",
    img: "https://3dmorphic.com/wp-content/uploads/2022/03/ROB3.png",
    description: "Robert is a recent graduate at the University of New South Wales with a Bachelor's degree in Mechanical Engineering. In December 2021 Rob joined 3DMorphic with a background in manufacturing and \
    metal processing. He is responsible for the post processing of extremely intricate spinal devices, ensuring utmost quality of the devices prior to surgery. Rob also undertakes other laboratory activities such \
    as ultrasonic cleaning, BioModel preparation and management of device history records."
  },
  {
    name: "Charles Furtado",
    position: "Medical Representative",
    img: "https://3dmorphic.com/wp-content/uploads/2022/06/Picture2-1.png",
    description: "Charles holds a Bachelor of Business and a Diploma in Project Management as well as over four years of Medial Representative and Clinical Support Specialist experience. Previously, Charles worked \
    for Johnson & Johnson Brazil as a medical representative, applying his technical knowledge for clinical support in Electrophysiology (EP Study). Here he collaborated with surgeons and technicians around the \
    country to aid in patient monitoring and supported pre, during and post surgery equipment set up and use. Charles joined 3DMorphic in May 2022 and is leading the Sales and Clinical Support Department."
  },
];
