import React, { useState } from 'react';
import { IconButton, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const SliderWithArrows = ({ images, arrowColor = 'white' }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    if (currentSlide < images.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handlePrev = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Left Arrow */}
      <IconButton
        onClick={handlePrev}
        disabled={currentSlide === 0}
        sx={{ position: 'absolute', left: '10px', zIndex: 10 }}
      >
        <ArrowBackIosIcon sx={{ color: arrowColor }} />
      </IconButton>

      {/* Image Slide */}
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <img
          src={images[currentSlide]}
          alt={`Slide ${currentSlide + 1}`}
          style={{
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
        />
      </Box>

      {/* Right Arrow */}
      <IconButton
        onClick={handleNext}
        disabled={currentSlide === images.length - 1}
        sx={{ position: 'absolute', right: '10px', zIndex: 10 }}
      >
        <ArrowForwardIosIcon sx={{ color: arrowColor }} />
      </IconButton>
    </Box>
  );
};

export default SliderWithArrows;
