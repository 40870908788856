import React from 'react';
import { makeStyles } from '@material-ui/core';
import ImageTextSection from "../components/ImageTextSection"; 
import { useTypographyStyles, useLayoutStyles } from '../styles';

const useStyles = makeStyles(theme => ({

  globalContainer: {
    maxWidth: '100%', // Changed from 1400px to 100%
    marginLeft: 0, // Keep it 0
    marginRight: 0, // Keep it 0
    padding: 0, // Keep it 0
  },

  mainHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 60,
    fontSize: '3.75rem',
    fontWeight: 'normal',
    color: '#53595D'
  },
  introText: {
    textAlign: 'justify',
    marginTop: 0,
    fontSize: '1.3rem',
    color: '#53595D',
    lineHeight: 1.5,
  },

  greyBackgroundLeft: {
    backgroundColor: '#f2f2f2',
    position: 'relative',
    '::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: '50%',
    },
  },
  greyBackgroundRight: {
    backgroundColor: '#f2f2f2',
    position: 'relative',
    '::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: '50%',
    },
  },
  
  imageTextSectionRow: {
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 20,
    flexDirection: 'row',
    '@media (max-width: 768px)': {
      backgroundColor: "#F6F6F6",
      flexDirection: 'column-reverse',
    },
  },

  headerParagraph: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down(600)]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  imageSection: {
    marginLeft: 10,
    marginRight: 10,
    width: '50%',
    '@media (max-width: 768px)': {
      width: '80%',
    },
    height: 'auto',
    display: 'block',
  },
  textSectionTitle: {
    marginLeft: 20,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'left',
    color: '#53595D',
    fontSize: '3rem',
    fontWeight: 'normal',
    lineHeight: 1.5,
  },
  textSectionContent: {
    marginLeft: 20,
    marginRight: 10,
    marginBottom: 10,
    textAlign: 'left',
    color: '#53595D',
    fontSize: '1.3rem',
    fontWeight: 'normal',
    lineHeight: 1.5,
    [theme.breakpoints.up(600)]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },

  whiteGreyBackground: {
    background: 'linear-gradient(to right, white 50%, #F6F6F6 50%)',  
    [theme.breakpoints.down(920)]: {
        background: 'linear-gradient(to right, white 50%, white 50%)'
    },
    color: 'black',
    marginBottom: 0, 
    marginTop: 0, 
  },

  greyWhiteBackground: {
    background: 'linear-gradient(to right, #F6F6F6 50%, white 50%)',
    [theme.breakpoints.down(920)]: {
      background: 'linear-gradient(to right, #F6F6F6 50%, #F6F6F6 50%)'
    },
    marginBottom: 0, 
    marginTop: 0, 
  },


  contentContainer: {
    margin: '0 auto',
    padding: `0 ${theme.spacing(4)}px`, // Adjust the horizontal padding as needed
    maxWidth: '1200px', // Adjust the max width as needed
    position: 'relative', // This will be the anchor for absolute positioning
    paddingTop: 1, // Remove top padding
    paddingBottom: 2, // Remove bottom padding
    height: 'auto', // Adjust as needed
    
  },

}));


const ImageBackgroundContainer = ({ children, isGreyBackgroundToLeft }) => {
  const classes = useStyles();
  const containerStyle = isGreyBackgroundToLeft ? classes.greyBackgroundLeft : classes.greyBackgroundRight;

  return <div className={containerStyle}>{children}</div>;
};

export default function Services() {
  const classes = useStyles();
  const typogClasses = useTypographyStyles();
  const layoutClasses = useLayoutStyles();

  return (
    <div className={layoutClasses.globalContainer}>
      <div className={layoutClasses.TitleInfoContainer}>
        <div className={`${layoutClasses.leftRightMargin} ${layoutClasses.topBottomMargin} ${layoutClasses.leftRightPadding} ${typogClasses.textJustifyCentre}`}>
          <p className={typogClasses.pageTitle}>Implant Integrated <strong>Services</strong></p>
          <div className={typogClasses.pageInfo}>
            Our commitment extends beyond just the product; 
            we provide a seamless experience, including biomodels, 
            virtual surgery planning, instrumentation and intra-operative support, 
            ensuring the optimal performance of our products.
          </div>
        </div>
      </div>
      <div className={layoutClasses.chessGreyWhiteBackground}>
       <div className={layoutClasses.contentContainer}>
        <ImageTextSection
          title="Biomodels"
          text="3D-printed anatomical models can be supplied with each implant, 
                enabling surgeons to perform procedures using accurate patient-specific replicas."
          imageUrl="https://d3fcjysp85tqc8.cloudfront.net/P6.webp"
          imageLeft={true}
          textStyle={{ textAlign: 'justify' }} 
        />
        </div>
      </div>
      <div className={layoutClasses.chessWhiteGreyBackground}>
       <div className={layoutClasses.contentContainer}>
      <ImageTextSection
        title="Virtual Surgery Planning"
        text="3DMorphic works closely with surgeons to design an implant that will treat the patient’s
         pathology and achieve surgical goals for correction. A Virtual Surgery Plan is provided,
         enabling surgeons to visualise the procedure with the patient specific implants, reducing intra-operative risk."
        imageUrl="https://d3fcjysp85tqc8.cloudfront.net/P7.webp"
        imageLeft={false}
        textStyle={{ textAlign: 'justify' }} 
      />
        </div>
      </div>
      <div className={layoutClasses.chessGreyWhiteBackground}>
       <div className={layoutClasses.contentContainer}>
      <ImageTextSection
        title="Instrumentation"
        text="Tailored surgical instruments are provided for every procedure, meeting surgeon requirements
              and ensuring a smooth implantation of the 3DMorphic device."
        imageUrl="https://d3fcjysp85tqc8.cloudfront.net/P8.webp"
        imageLeft={true}
        textStyle={{ textAlign: 'justify' }} 
      />
        </div>
      </div>
      <div className={layoutClasses.chessWhiteGreyBackground}>
       <div className={layoutClasses.contentContainer}>
      <ImageTextSection
        title="Intra-Operative Support"
        text="Our expert team provides guidance and support throughout the surgical procedure."
        imageUrl="https://d3fcjysp85tqc8.cloudfront.net/9.webp"
        imageLeft={false}
        textStyle={{ textAlign: 'justify' }} 
        hasDepthEffect={true}
      />
     </div>
      </div>
    </div>
  );
}